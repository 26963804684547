<!-- 通讯录管理 -->
<template>
  <div style="height:100%;">
    <div class="page-header">通讯录管理</div>
    <div style="height:calc(100% - 66px);" class="content flex">
      <div class="left">
        <!-- 搜索成员、部门 -->
        <!-- <el-input placeholder="待开放" prefix-icon="el-icon-search" v-model="searchStr" /> -->
        <el-button type="primary" @click="popAddDept" style="margin-top: 30px;">
          <span class="icon iconfont icon-tianjia"></span>&nbsp;新建部门
        </el-button>
        <div style="border-top: 1px solid #E6E6E6;margin-top: 30px;">
          <el-tree
            :data="depts"
            :props="defaultProps"
            accordion
            icon-class="el-icon-arrow-right"
            @node-click="clickDept"
            style="background: none;padding: 0 10px;"
          >
            <span class="flex flex-justify-between custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span>
                <el-popover placement="right" trigger="hover" popper-class="dept-edit-popover">
                  <div class="pop-menu">
                    <div @click="popAddDept(data.id, true)">
                      <span class="icon iconfont icon-tianjia"></span>添加子部门
                    </div>
                    <div @click="popModDept(data)">
                      <span class="icon iconfont icon-bianji"></span>修改名称
                    </div>
                    <div v-if="!(data.children && data.children.length > 0)" @click="delDept(data)">
                      <span class="icon iconfont icon-shanchu"></span>删除
                    </div>
                    <!-- <div>部门id{{ data.id }}</div> -->
                  </div>
                  <span slot="reference" class="icon iconfont icon-gengduo"></span>
                </el-popover>
              </span>
            </span>
          </el-tree>
        </div>
        <div @click="clickDept({id:0})" :class="{'active':nodept==0}" class="nodept">未分部门成员</div>
      </div>
      <div class="flex-1" style="overflow: auto;padding: 20px;">
        <div style="padding: 13px 0 26px 0;" class="flex">
          <div class="flex-1">
            <el-button @click="popSetUserDept">设置所在部门</el-button>
            <el-button type="primary" @click="goto('/invite?from=contact')">邀请同事</el-button>
            <el-button v-if="privids.indexOf(1009)>=0" @click="delUsers">解绑</el-button>
          </div>
          <div class="input-block flex">
            <el-input v-model="searchVal" placeholder="部门、姓名、职务、手机、邮箱" prefix-icon="el-icon-search"/>
            <el-button @click="search">搜索</el-button>
          </div>
        </div>
        <div style="height:calc(100% - 135px)">
          <el-table :data="users" style="width: 100%;height:100%;" ref="_table" stripe>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="member_id" label="员工编号" width="100"></el-table-column>
            <el-table-column prop="name" label="姓名" width="100"></el-table-column>
            <el-table-column label="职务" width="100">
              <template slot-scope="scope">
                <span v-for="(role, idx) of scope.row.roles" :key="idx">
                  {{
                  role.name
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="部门">
              <template slot-scope="scope">
                <span v-for="(dept, idx) of scope.row.depts" :key="idx">{{ dept.name }}&nbsp;</span>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="实名状态" width="100px">
              <template slot-scope="scope">
                <div v-if="scope.row.cert_stat==0">未认证</div>
                <div v-if="scope.row.cert_stat==1">认证中</div>
                <div v-if="scope.row.cert_stat==2">认证通过</div>
                <div v-if="scope.row.cert_stat==3">认证失败</div>
              </template> 
            </el-table-column>
            <el-table-column prop="stat" label="账号状态" width="100px">
              <template slot-scope="scope">
                <div v-if="scope.row.stat==0">备用</div>
                <div v-if="scope.row.stat==1">正常</div>
                <div v-if="scope.row.stat==2">禁止登录</div>
                <div v-if="scope.row.stat==3">已解绑</div>
              </template> 
            </el-table-column>
            <el-table-column prop="mobile" label="手机" width="120px">
              <template slot-scope="scope">
                <div v-if="scope.row.ori_mobile">{{scope.row.ori_mobile}}</div>
                <div v-else>{{scope.row.mobile}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="mail" label="邮箱" width="250px"></el-table-column>
            <el-table-column label="操作" width="240px">
              <template slot-scope="scope">
                <a @click="popViewUser(scope.row)" class="operate-btn">查看</a>
                <a @click="popEditUser(scope.row)" class="operate-btn">编辑</a>
                <a v-if="scope.row.comp_creator && scope.row.comp_creator==1" 
                  @click="changeAdmin(scope.row)" class="operate-btn">管理员换绑</a>
              </template> 
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 12px 0;">
          <el-pagination v-if="pageShow"
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page"
            :page-size="queryOpt.pagesize"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      width="500px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
      center
    >
      <EditDept
        v-if="popType == 1"
        :dept="curDept"
        :depts="deptsOrigin"
        @onSuccess="onDeptSaved"
        @onClose="popVisible = false"
      ></EditDept>
      <EditUser
        v-if="popType == 2"
        :popTitle="popTitle"
        :id="curEditUserId"
        @onSuccess="onUserSaved"
        @onClose="popVisible = false"
        :destroy-on-close="true"
        :editing="isEditingUser"
      ></EditUser>
      <SetUserDept
        v-if="popType == 3"
        :userIds="selectedUserIds"
        @onSuccess="onUserDeptSaved"
        @onClose="popVisible = false"
      ></SetUserDept>
      <DeleteDeptDialog
        v-if="popType == 4"
        :dept="curDept"
        @onSuccess="onDeptDeleted"
        @onClose="popVisible = false"
      ></DeleteDeptDialog>
      <ChangeAdmin
        v-if="popType == 5"
        :userItem="userItem"
        @onSuccess="refreshMembers"
        @onClose="popVisible = false"
      ></ChangeAdmin>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { getDeptsByCompId, getMembersByDept, delDept } from "@/service/company";
import { delUserByIds, getRolesSearchMember } from "@/service/account";
import { fetchTree } from "@/util";
import EditUser from "./EditUser.vue";
import EditDept from "./EditDept.vue";
import SetUserDept from "./SetUserDept.vue";
import DeleteDeptDialog from "./DeleteDeptDialog.vue";
import ChangeAdmin from "./ChangeAdmin.vue";
export default {
  components: {
    EditUser,
    EditDept,
    SetUserDept,
    DeleteDeptDialog,
    ChangeAdmin
  },
  data() {
    return {
      searchVal: "",
      popVisible: false,
      popTitle: "",
      curDept: null,
      deptsOrigin: [],
      depts: [],
      dept_id: -1,
      users: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      curEditUserId: null,
      isEditingUser: false,
      popType: null,
      queryOpt: {
        pagesize: 15,
        page: 1,
        comp_id: this.$store.state.loginUser.comp.id,
        dept_id: -1,
        k:null,
      },
      total: 0,
      nodept:0,// 未命名的分组成员
      userItem:null,
      pageShow: true, // 刷新分页显示
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true
    }
  },
  created() {
    this.refreshDept();
    this.debounceRefreshItems = _.debounce(this.refreshMembers, 500);
    this.refreshMembers();
  },
  computed: {
    selectedUserIds: function() {
      let ids = this.$refs._table.store.states.selection.reduce((pre, cur) => {
        pre.push(cur.id);
        return pre;
      }, []);
      return ids;
    },
    privids() {
      return _.get(this.$store.state, "loginUser.user.privids", []);
    }
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    search() {
      let queryOpt = JSON.parse(JSON.stringify(this.queryOpt));
      queryOpt.k = this.searchVal;
      queryOpt.page = 1;
      getRolesSearchMember(queryOpt).then(u => {
        this.users = u.list;
        if (u.total) {
          this.total = u.total;
        }
        console.log(this.users);
      }).catch(err => {
        this.$message.error(err.message);
      });
      this.pageShow = false;
      this.$nextTick(()=>{
          this.pageShow = true;
      })
    },
    refreshDept() {
      getDeptsByCompId(this.$store.state.loginUser.comp.id)
        .then(res => {
          this.deptsOrigin = Object.values(res);
          this.depts = fetchTree(Object.values(res), 0, null);
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    refreshMembers() {
      getMembersByDept(this.queryOpt)
        .then(u => {
          this.users = u.list;
          if (u.total) {
            this.total = u.total;
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 切换部门
    clickDept(data) {
      console.log(data);
      if (!(data.children && data.children.length > 0)) {
        this.queryOpt.dept_id = data.id;
        this.refreshMembers();
      }
      if (data.id==0) {
        this.nodept = data.id;
      } else {
        this.nodept = null;
      }
    },
    popAddDept(parentId, isSub = false) {
      this.popType = 1;
      this.popTitle = `新建${isSub ? "子" : ""}部门`;
      this.curDept = {
        isSub,
        dept_pid: parentId,
        dept_name: null,
        comp_id: this.$store.state.loginUser.comp.id
      };
      this.popVisible = true;
    },
    popModDept(dept) {
      console.log(dept);
      this.popType = 1;
      this.popTitle = "编辑部门";
      this.curDept = {
        isMod: true,
        dept_id: dept.id,
        dept_pid: dept.pid,
        dept_name: dept.name,
        comp_id: this.$store.state.loginUser.comp.id
      };
      this.popVisible = true;
    },
    onDeptSaved() {
      this.popVisible = false;
      this.refreshDept();
    },
    delDept(dept) {
      this.popType = 4;
      this.popTitle = "温馨提示";
      this.curDept = {
        dept_id: dept.id,
        dept_pid: dept.pid,
        dept_name: dept.name,
        comp_id: this.$store.state.loginUser.comp.id
      };
      this.popVisible = true;
    },
    onDeptDeleted() {
      this.popVisible = false;
      this.refreshDept();
    },
    // 批量删除用户
    delUsers() {
      if (this.selectedUserIds.length > 0) {
        this.$confirm("确认是否删除?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          delUserByIds(
            this.$store.state.loginUser.comp.id,
            this.selectedUserIds
          )
            .then(() => {
              this.$message.success("删除成功");
              this.refreshMembers();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        });
      } else {
        this.$message.error("请勾选用户的姓名");
      }
    },
    // 编辑用户
    popEditUser(row) {
      // this.$router.push('/contact_edit_user/'+row.id);
      this.isEditingUser = true;
      this.curEditUserId = row.id;
      this.popType = 2;
      this.popTitle = "编辑" + row.name;
      this.popVisible = true;
    },
    // 查看用户
    popViewUser(row) {
      this.isEditingUser = false;
      this.curEditUserId = row.id;
      this.popType = 2;
      this.popTitle = "查看" + row.name;
      this.popVisible = true;
    },
    changeAdmin(item) {
      this.userItem = item;
      this.popType = 5;
      this.popTitle = '换绑管理员';
      this.popVisible = true;
    },
    // lookUser(row) {
    //   this.$router.push(`/personSet/${row.id}`)
    // },
    // 用户保存成功
    onUserSaved() {
      this.$message.success("保存成功");
      this.popVisible = false;
      this.refreshMembers();
    },
    // 弹出部门选择框
    popSetUserDept() {
      if (this.selectedUserIds.length > 0) {
        // setMembersDept(this.$store.state.loginUser.comp.id,ids)
        console.log(this.depts);
        if (this.depts && this.depts.length>0) {
          this.popType = 3;
          this.popTitle = "请选择部门";
          this.popVisible = true;
        } else {
          this.$message.error("请新建部门");
        }
        
      } else {
        this.$message.error("请勾选用户的姓名");
      }
    },
    onUserDeptSaved() {
      this.$message.success("设置成功");
      this.popVisible = false;
      this.refreshMembers();
    }
  }
};
</script>
<style>
.dept-edit-popover {
  padding: 0;
}
</style>
<style scoped>
.custom-tree-node {
  font-size: 14px;
  width: 100%;
}
.pop-menu {
  padding: 0 20px;
}
.pop-menu > div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}
.pop-menu > div:last-child {
  /* text-align: center; */
  /* padding: 30px 0; */
  color: #808080;
  border-bottom: 0;
}

.pop-menu > div:hover {
  background: #eee;
}

.pop-menu > div > span {
  margin-right: 10px;
}
.nodept {
  height: 48px;
  line-height: 48px;
  font-size:14px;
  color: #606266;
  text-align:left;
  margin:0 10px;
  padding: 0 10px;
  cursor: pointer;
}
.nodept:hover {
  background: #F5F7FA;
}
.nodept.active {
  background: #F5F7FA;
}
div.content >>> .el-table {
  height: 100%;
}
div.content >>> .el-table__body-wrapper {
  height: calc(100% - 49px);
  overflow-y: auto;
}
div.content >>> .el-table__header-wrapper {
  width: calc(100% - 16px);
}

div.content >>> .el-table .cell {
  text-align: center;
}
div.content >>> .el-table .el-button {
  margin-left: 10px;
}
div.content >>> .el-icon-arrow-right {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: -1px;
}
div.content >>> .el-tree-node__content {
  height: 48px;
}
div.content >>> th {
  color: #4d4d4d;
  font-size: 16px;
}
.left >>> .el-input .el-input__inner {
  background-color: #e7e7e7;
  border-radius: 20px;
}
div >>> .el-dialog__body {
  text-align: center;
}
</style>
<style lang="less" scoped>
.input-block {
    .el-input {
        width:270px;
    }
    .el-button {
        width: 76px;
        left: -4px;
        position: relative;
        z-index: 2;
    }
}
.icon-gengduo {
  color: #ccc;
}
.left {
  width: 200px;
  background-color: #fff;
  box-shadow: 2px 0px 4px 0px rgba(208, 208, 208, 0.5);
  border: 1px solid rgba(230, 230, 230, 1);
  text-align: center;
  .el-button {
    width: 160px;
  }
  .el-input {
    width: 180px;
    margin-top: 30px;
  }
}
.operate-btn {
  border-right: 1px solid #e4e7f4;
  padding: 0 10px;
  display: inline-block;
  line-height: 1;
  color: #24B1EB;
  &:last-child {
    border-right: 0;
  }
}
</style>
